var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"500px","transition":"slide-y-transition"},model:{value:(_vm.addEditDialog),callback:function ($$v) {_vm.addEditDialog=$$v},expression:"addEditDialog"}},[_c('v-card',{staticClass:"dialog-card-border"},[_c('v-card-title',{staticClass:"text-h6 font-weight-light"},[_vm._v(" "+_vm._s(_vm.editMode ? _vm.$t('Update Supplier') : _vm.$t('Add New Supplier'))+" ")]),_c('validation-observer',{ref:"theForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":("* " + (_vm.$t('Name'))),"outlined":"","dense":"","color":_vm.$_input_color(),"error-messages":errors},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Phone'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":("* " + (_vm.$t('Phone'))),"outlined":"","dense":"","color":_vm.$_input_color(),"error-messages":errors},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Email')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Email'),"outlined":"","dense":"","color":_vm.$_input_color(),"error-messages":errors},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Address')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":_vm.$t('Address'),"outlined":"","dense":"","rows":"3","color":_vm.$_input_color(),"error-messages":errors},model:{value:(_vm.formData.address),callback:function ($$v) {_vm.$set(_vm.formData, "address", $$v)},expression:"formData.address"}})]}}],null,true)})],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"py-3"},[_c('app-form-tip'),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","outlined":"","small":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")]),_c('app-submit-button',{attrs:{"edit-mode":_vm.editMode}})],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }